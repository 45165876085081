import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var myFooter = function myFooter() {
  return import("./common/myFooter");
};

var photo = function photo() {
  return import("./common/photo");
};

var proTag = function proTag() {
  return import("./common/proTag");
};

export default {
  components: {
    photo: photo,
    proTag: proTag,
    myFooter: myFooter
  },
  data: function data() {
    return {
      photoPagination: {
        current: 1,
        size: 10,
        total: 0,
        resourceType: "lovePhoto",
        classify: ""
      },
      photoTitleList: [],
      photoList: []
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getPhotoTitles();
  },
  mounted: function mounted() {},
  methods: {
    getPhotoTitles: function getPhotoTitles() {
      var _this = this;

      this.$http.get(this.$constant.baseURL + "/webInfo/listAdminLovePhoto").then(function (res) {
        if (!_this.$common.isEmpty(res.data)) {
          _this.photoTitleList = res.data;
          _this.photoPagination = {
            current: 1,
            size: 10,
            total: 0,
            resourceType: "lovePhoto",
            classify: _this.photoTitleList[0].classify
          };

          _this.changePhoto();
        }
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    changePhotoTitle: function changePhotoTitle(classify) {
      if (classify !== this.photoPagination.classify) {
        this.photoPagination = {
          current: 1,
          size: 10,
          total: 0,
          resourceType: "lovePhoto",
          classify: classify
        };
        this.photoList = [];
        this.changePhoto();
      }
    },
    pagePhotos: function pagePhotos() {
      this.photoPagination.current = this.photoPagination.current + 1;
      this.changePhoto();
    },
    changePhoto: function changePhoto() {
      var _this2 = this;

      this.$http.post(this.$constant.baseURL + "/webInfo/listResourcePath", this.photoPagination).then(function (res) {
        if (!_this2.$common.isEmpty(res.data)) {
          _this2.photoList = _this2.photoList.concat(res.data.records);
          _this2.photoPagination.total = res.data.total;
        }
      }).catch(function (error) {
        _this2.$message({
          message: error.message,
          type: "error"
        });
      });
    }
  }
};